/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Solutions from 'components/shared/Solutions'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...SolutionsPageFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast, wordpress_id },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero small className="mb-lg-5" content={acf.banner} />

      <Solutions className="pt-lg-5" parentId={wordpress_id} />
    </Layout>
  )
}

export default PageTemplate
